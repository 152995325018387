import $ from './base'

export default {
  register({verifyCode, userName, password, email, type, sheng,shi,xian,birthday,school,schoolType,grade,phone,org,orgType,remark,shouke,addr,className}) {
    return $.post('/register', {verifyCode, userName, password, email, type, sheng,shi,xian,birthday,school,schoolType,grade,phone,org,orgType,remark,shouke,addr,class: className})
  },
  login({ userName, password }) {
    return $.post('/login', { userName, password })
  },
  logout() { 
    return $.get('/logout')
  },
  updateUser({id, password,phone,birthday,type}) { //type:9 all access;
    return $.post('/user/update', {id, password,phone,birthday,type})
  },
  updateUser2(data) { //type:9 all access;
    return $.post('/user/update', data)
  },
  hasUser(text) {
    return $.post('/hasUser', {text})
  },
  getVerifyCode(mail) {
    return $.post('verifyCode', {mail})
  },
  getUserList() {
    return $.post('/user/find')
  },
  // 找回密码时的邮箱验证码
  resetPasswordYzm(email) {
    return $.post('/reset/password/?email=' + email)
  },
  // 重制密码
  resetPassword(data) {
    return $.post('/user/set/password',data)
  }
}