import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAMap from 'vue-amap';
import App from './App.vue'
import router from './router'
import 'vue-area-linkage/dist/index.css'; // v2 or higher
import VueAreaLinkage from 'vue-area-linkage';
import VCalendar from 'v-calendar';
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
 

import '@/utils/date'

import Distpicker from 'v-distpicker'
Vue.component('v-distpicker', Distpicker)
// import ECharts modules manually to reduce bundle size
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  LineChart,
  BarChart,
  PieChart,
  GaugeChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  LegendComponent,
  MarkPointComponent,
  ToolboxComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  LineChart,
  BarChart,
  PieChart,
  GaugeChart,
  GridComponent,
  TooltipComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  LegendComponent,
  MarkPointComponent,
  ToolboxComponent
]);
Vue.component('v-chart', ECharts)

Vue.config.productionTip = false
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '66ab7eb04609ab83bc19cd3b78f3e35d',
  plugin: ['AMap.Autocomplete','AMap.Geocoder', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.AMapManager','AMap.Geolocation'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4',
});
Vue.use(ElementUI);
Vue.use(VueAreaLinkage)
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
