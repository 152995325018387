import axios from 'axios';
import $cookies from 'vue-cookies'
import router from '@/router'

const BASE = 'https://kxwj.cn';
// const BASE = ' http://192.168.31.148:8086';
const ROOT = `${BASE}/server`
const instance = axios.create({
  baseURL: `${ROOT}`,
});
instance.interceptors.request.use(config => {
  let authorization = $cookies.get('Authorization');
  let currentUser = $cookies.get('currentUserName');
  if (authorization) {
    $cookies.set('Authorization', authorization, '30MIN')
    $cookies.set('currentUserName', currentUser, '30MIN')
    config.headers.Authorization = 'Bearer ' + authorization;
  }
  return config
}, error => {
  return Promise.reject(error)
})
instance.interceptors.response.use(response => {
  return response.data
}, error => {
  if (error.response && error.response.status === 401) {
    router.push('/login')
  }
  return Promise.reject(error)
})

export default instance;
export { BASE, ROOT };
