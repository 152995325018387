<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "App",
  data () {
    return {};
  },
};
</script>
<style lang="less">
@import url('@/styles/reset.less');
.el-pagination {
  margin-top: 10px;
  text-align: center;
}
.amap-marker-content {
  white-space: normal !important;
}
.amap-copyright,
.amap-mcode,
.amap-logo {
  display: none !important;
}
.icon-on {
  width: 20px;
  height: 20px;
}
.marker-content {
  position: relative;
  width: 20px;
  height: 20px;
  .icon {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .detail {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 10px);
    transform: translateX(-50%);
    width: 200px;
    background: #003572;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    line-height: 1.2;
    white-space: nowrap;
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: calc(50% - 7px);
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 10px solid #003572;
    }
    .name {
      display: flex;
      align-items: center; /*垂直居中*/
      justify-content: center; /*水平居中*/
      .name_center {
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
        font-weight: normal;
        color: #00a0e9;
      }
    }
    .time {
      font-size: 14px;
      font-weight: normal;
      color: #036eb8;
      text-align: center;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 180px;
      }
    }
    .swiper {
      width: 100%;
      height: 200px;
      --swiper-navigation-size: 15px; /* 设置按钮大小 */
      .swiper-wrapper {
        .swiper-slide {
          .list {
            display: flex;
            align-items: center; /*垂直居中*/
            justify-content: center; /*水平居中*/
            .item {
              width: 150px;
              height: 25px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
