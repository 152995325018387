<template>
  <div class="body">
    <div class="head transition" :style="{ height: height }">
      <div
        class="logo-wrapper transition"
        :style="{ height: logoHeight, cursor: 'pointer' }"
        @click="toLink(0)"
        style="display: flex;"
      >
        
        <div>
          <img src="../assets/logo_new.png" alt="" />
        </div>
        <!-- <div style="margin-left: 10px;margin-top: 25px;"> 
          <div style="font-size: 30px; color: #0aa1eb;">中国科普学习和观测联盟(CASO)</div>
          <div style="margin-top: 10px;">CHINESE ALLIANC OF SCIENCE AND OBSERVATION</div>
        </div> -->
        
        <!-- <img class="logo-text" src="../assets/image/logo-text.png" alt="" /> -->
      </div>
      <ul>
        <li :class="{ active: 0 == navIndex }">
          <router-link to="/">首页</router-link>
        </li>
        <li :class="{ active: 1 == navIndex }">
          <span @click="gointomap()">科学观测网</span>
        </li>
        <li :class="{ active: 2 == navIndex }"> 
          <router-link to="/qixiang">在线学习</router-link>
        </li>
        <li :class="{ active: 2 == navIndex }">
          <router-link to="/school">科学园地</router-link>
        </li>
        <li :class="{ active: 2 == navIndex }">
          <router-link to="/shop">商城</router-link>
        </li>
        <!-- <li v-for="(item, i) in navList"
            :key="i"
            :class="{ active: i == navIndex }">
          <router-link :to="item.path">{{ item.name }}</router-link>
        </li>
        <li v-for="(item, i) in navList"
            :key="i"
            :class="{ active: i == navIndex }">
          <router-link :to="item.path">{{ item.name }}</router-link>
        </li>
        <li v-for="(item, i) in navList"
            :key="i"
            :class="{ active: i == navIndex }">
          <router-link :to="item.path">{{ item.name }}</router-link>
        </li>
        <li v-for="(item, i) in navList"
            :key="i"
            :class="{ active: i == navIndex }">
          <router-link :to="item.path">{{ item.name }}</router-link>
        </li> -->
        <li class="other" @click="lxwm">联系我们</li>
        <li class="other" v-if="currentUserName">
          {{ currentUserName }} <span class="logout" @click="logout">退出</span>
        </li>
        <li class="other" v-else @click="login">登录</li>
      </ul>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
    <div class="fixed-bar">
      <div class="totop" @click="goTop">
        <i class="el-icon-arrow-up"></i>
        <span>顶部</span>
      </div>
      <ul class="links">
        <li class="item blue">观测联盟</li>
        <li class="item purple" @click="lxwm">联系我们</li>
      </ul>
    </div>
    <div class="bottom">
      <div class="text">
        <p>
          <span>CASO联盟</span> | <span>活动合作</span> | <span>联系方式</span>
        </p>
        <p>
          版权所有：北京春孚科技有限公司 烟台创昇教育科技有限公司
          <a :href="beian.url" target="_blank">{{ beian.label }}</a>
        </p>
      </div>
      <img src="../assets/image/qrcode.png" alt="" />
    </div>
  </div>
</template>

<script>
import $cookies from "vue-cookies";
import debounce from "lodash.debounce";
import { $user } from "@/services";
const beian = {
  "www.chunfutech.cn": {
    label: "京ICP备20007798号-1",
    url: "https://beian.miit.gov.cn/",
  },
  "kxwj.cn": {
    label: "鲁ICP备18057156号-2",
    url: "https://beian.miit.gov.cn",
  },
};
const getBeian = () => {
  return beian[window.location.hostname] || beian["kxwj.cn"];
};
export default {
  name: "Index",
  data() {
    return {
      navIndex: 0,
      navList: [
        { name: "首页", path: "/" },
        { name: "科学观测网", path: "/map" },
        { name: "在线学习", path: "/qixiang" },
        { name: "科学园地", path: "/school" },
        { name: "商城", path: "/shop" },
      ],
      isFixed: false,
      scrollTop: 0,
      currentUserName: "",
      beian: getBeian(),
    };
  },
  computed: {
    height() {
      return 140 - (80 * Math.min(this.scrollTop, 150)) / 150 + "px";
    },
    logoHeight() {
      return 100 - (60 * Math.min(this.scrollTop, 150)) / 150 + "px";
    },
  },
  created() {
    this.currentUserName = $cookies.get("currentUserName");
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", debounce(this.scrollHandler), 50);
    });
  },
  destroy() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
  methods: {
    gointomap() {
      const router = this.$router.resolve({ path: "/map" });
      window.open(router.href, "_blank");
    },
    gointo() {
      window.location = "https://wap.miit.gov.cn/";
    },
    toLink(index) {
      console.log(1);
      if (index == this.navIndex) {
        return;
      }

      this.navIndex = index;
      const path = this.navList[index].path;
      if (path != "") {
        document.scrollTop = 0;
        this.$router.push(path);
      }
    },
    lxwm() {
      // window.location = "https://mp.weixin.qq.com/s/A_H-2p9f1EV4E9Jbtxamug";
      // window.location.href = "https://mp.weixin.qq.com/s/SlgzdBzbmuD_16i2ASMbkg";
      window.open(
        "https://mp.weixin.qq.com/s/SlgzdBzbmuD_16i2ASMbkg",
        "_blank"
      );
    },
    scrollHandler() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop; //获取滚动距离
      this.isFixed = scrollTop >= 150;
      this.scrollTop = scrollTop;
    },
    login() {
      if (this.$route.name === "login") return;
      this.$router.push({
        name: "login",
        query: { redirect: this.$route.fullPath },
      });
    },
    logout() {
      $user.logout().then((res) => {
        if (res.code == 0) {
          this.currentUserName = "";
          $cookies.remove("Authorization");
          $cookies.remove("currentUserName");
        }
      });
    },
    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="less">
.transition {
  transition: all 0.3s;
}
.fixed-bar {
  position: fixed;
  z-index: 9999;
  right: 20px;
  bottom: 20px;
  width: 60px;
  padding: 8px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .totop {
    cursor: pointer;
    color: #e01121;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    span {
      margin: 5px 0;
    }
  }
  .links {
    .item {
      margin-top: 6px;
      cursor: pointer;
      width: 44px;
      height: 48px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #fff;
      word-break: break-all;
      display: flex;
      align-items: center;
      line-height: 1.4;
      &.blue {
        background: linear-gradient(0deg, #5ff2fe 0%, #0679e5 100%);
      }
      &.purple {
        background: linear-gradient(0deg, #9b5ffe 0%, #f6bc61 100%);
      }
    }
  }
}
.body {
  max-width: 1920px;
  margin: 0 auto;
  background-color: white;

  > .head {
    height: 140px;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 9;
    .logo-wrapper {
      position: relative;
      left: 129px;
      top: 10px;
      user-select: none;
      display: inline-block;
      img {
        height: 100%;
        vertical-align: middle;
      }
      .logo-text {
        height: 60%;
      }
    }

    > ul {
      margin: 0;
      position: absolute;
      right: 200px;
      top: 50%;
      transform: translateY(-50%);

      li {
        display: inline-block;
        margin: 0 10px;
        color: #6174ae;
        padding: 1px 5px;
        cursor: pointer;
        font-size: 20px;
        a {
          color: inherit;
        }
        .router-link-exact-active {
          color: white;
          background-color: #076fcc;
          border-radius: 5px;
        }

        &.other {
          color: #e74b6e;
          position: relative;
          &:hover {
            .logout {
              display: initial;
            }
          }
          .logout {
            display: none;
            font-size: 14px;
            width: 100%;
            position: absolute;
            left: 0;
            top: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  > .content {
    padding-top: 140px;
    background-color: #f4f4f4;
  }

  > .bottom {
    background-color: #049ef2;
    height: 220px;
    position: relative;

    > .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      text-align: center;
      > p {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 42px;
        margin: 0;

        &:last-child {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #002a5b;
        }
      }
      > :nth-child(2) {
        cursor: pointer;
      }
    }

    > img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20%;
    }
  }
}
</style>
