import Vue from 'vue';
import VueRouter from 'vue-router';

import homePath from '@/views/index.vue'

Vue.use(VueRouter);

const router = new VueRouter({
    routes: [
        {
            path: '/', component: homePath, meta: { title: "首页" }, children: [
                { path: '/', component: () => import('../views/home.vue'), meta: { title: "首页" }, },
                { path: '/school', component: () => import('../views/school.vue'), meta: { title: "科学园地" }, },
                { path: '/qixiang', component: () => import('../views/qixiang.vue'), meta: { title: "在线学习" }, name: 'onlineStudy' },
                { path: '/shop', component: () => import('../views/shop.vue'), meta: { title: "商城" }, },
                { path: '/showShop', component: () => import('../views/showShop.vue'), meta: { title: "商城" }, },
                { path: '/qixiang/showPpt', component: () => import('../views/showPpt.vue'), meta: { title: "课程PPT" }, name: 'study' },
                { path: '/qixiang/video', component: () => import('../views/video.vue'), meta: { title: "课程视频" }, name: 'video' },
                {
                    path: '/science', component: () => import('../views/science.vue'), meta: { title: "科学活动" }, name: 'science',
                },
                {
                    path: '/course', component: () => import('../views/course.vue'), meta: { title: "特色课程" }, name: 'course', children: [

                    ]
                },
                {
                    path: '/course/intro', name: 'courseIntro', component: () => import('../views/courseIntro.vue'), meta: { title: "气象STEM课程总体介绍" },
                },
                {
                    path: '/reader', name: 'reader', component: () => import('../views/file.vue'), meta: { title: "文章详情" },
                },
            ]
        },
        { name: 'login', path: '/login', component: () => import('../views/login.vue'), meta: { title: "登录" }, },
        { name: 'signup', path: '/signup', component: () => import('../views/signup.vue'), meta: { title: "注册" }, },
        { name: 'signType', path: '/signType', component: () => import('../views/signType.vue'), meta: { title: "账号注册" }, },
        { name: 'resetPassword', path: '/resetPassword', component: () => import('../views/gis/resetPassword.vue'), meta: { title: "找回密码" }, },
        { name: 'pay', path: '/pay', component: () => import('../views/pay/pay.vue'), meta: { title: "vip" }, },
        {
            name: 'gis',
            path: '/gis',
            component: () => import('../views/gis/gishome.vue'),
            children: [
                { name: 'map', path: '/map', component: () => import('../views/gis/gis.vue'), meta: { title: "科学观测网-GIS地图" }, },
                { name: 'detail', path: '/detail', component: () => import('../views/gis/gisdetail.vue'), meta: { title: "科学观测网-气象站" }, },
                { name: 'gisadd', path: '/gisadd', component: () => import('../views/gis/gisadd.vue'), meta: { title: "个人气象站新增" }, },
                { name: 'personal', path: '/personal', component: () => import('../views/gis/gispersonal.vue'), meta: { title: "个人气象站管理" }, },
                { name: 'gislist', path: '/gislist', component: () => import('../views/gis/gislist.vue'), meta: { title: "修改" }, },
                { name: 'gisuser', path: '/gisuser', component: () => import('../views/gis/gisuser.vue'), meta: { title: "user" }, },
                {   
                    name: 'site',
                    path: '/site',
                    component: () => import('../views/gis/gisSite.vue'), 
                },
            ]
        },
        // 第三届校园大赛报名页面
        { name: 'activity',path: '/activity',component: () => import('../views/activity/activity.vue'),meta: { title: "第三届校园气象观测和应用挑战赛" } },
        // 报名表
        { 
            name: 'signHome', 
            path: '/signHome', 
            component: () => import('../views/sign/signHome.vue'), meta: { title: "报名页面" } ,
            children:[
                { name: 'info', path: '/info', component: () => import('../views/sign/info.vue'), meta: { title: "填写报名信息" }, },
                { name: 'uploadData', path: '/uploadData', component: () => import('../views/sign/uploadData.vue'), meta: { title: "上传气象数据" }, },
                { name: 'signList', path: '/signList', component: () => import('../views/sign/signList.vue'), meta: { title: "报名列表" }, },
                { name: 'review', path: '/review', component: () => import('../views/sign/review.vue'), meta: { title: "评审" }, },
            ]
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }

})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {//判断是否有标题
        document.title = to.meta.title
    }
    next()//执行进入路由，如果不写就不会进入目标页
})

export default router;