import $ from './base'

export default {
  getFileList({ date, parentId }) {
    return $.post('/yuanDi/findFile', { date, parentId })
  },
  getMenuList({parentId}) {
    return $.post('/yuanDi/findMenu', { parentId })
  },
  getFileAndMenuList({parentId}) {
    return $.post('/yuanDi/findMenuAndFile', { parentId })
  }
}