import $ from './base'
import service from './service'
function windDegreeToDir(wd) {
  if (wd > 22.5 && wd <= 67.5) {
    return '东北风'; //东北
  } else if (wd > 67.5 && wd <= 112.5) {
    return '东风'; //东
  } else if (wd > 112.5 && wd <= 157.5) {
    return '东南风'; //东南
  } else if (wd > 157.5 && wd <= 202.5) {
    return '南风'; //南
  } else if (wd > 202.5 && wd <= 247.5) {
    return '西南风'; //西南
  } else if (wd > 247.5 && wd <= 292.5) {
    return '西风'; //西
  } else if (wd > 292.5 && wd <= 337.5) {
    return '西北风'; //西北
  } else if (wd > 337.5 || wd <= 22.5) {
    return '北风'; //北
  } else {
    return '无风向'; //无风向
  }
}
const dataHelper = (res) => {
  if(res.result === 1) {
    return {
      data: res.data.map( d => {
        return {
          ...d,
          // tempf: d.tempf == "" ? d.tempf : ((d.tempf - 32)/1.8).toFixed(1),
          // tempinf: d.tempinf == "" ? d.tempinf :((d.tempinf - 32)/1.8).toFixed(1),
          // baromabsin:(d.baromabsin*33.863882).toFixed(1),
          // baromrelin:(d.baromrelin*33.863882).toFixed(1),
          // maxdailygust:(d.maxdailygust*0.447).toFixed(1),
          // windgustmph:(d.windgustmph*0.447).toFixed(1),
          // windspeedmph:(d.windspeedmph*0.447).toFixed(1),
          // windspdmph_avg2m:(d.windspdmph_avg2m*0.447).toFixed(1),
          // windspdmph_avg10m:(d.windspdmph_avg10m*0.447).toFixed(1),
          // minutesrainin:(d.minutesrainin*25.4).toFixed(2),
          // hourlyrainin:(d.hourlyrainin*25.4).toFixed(2),
          // dailyrainin:(d.dailyrainin*25.4).toFixed(2),
          // weeklyrainin:(d.weeklyrainin*25.4).toFixed(2),
          // monthlyrainin:(d.monthlyrainin*25.4).toFixed(2),
          // yearlyrainin:(d.yearlyrainin*25.4).toFixed(2),
          // totalrainin:(d.totalrainin*25.4).toFixed(2),
          winddir10m : d.winddir_avg10m,
          winddir_avg10m: d.winddir_avg10m == "" ? "" : windDegreeToDir(d.winddir_avg10m)
        }
      })
    }
  }
}
const dataHelper1 = (res) => {
  if(res.result === 1) {
    console.log(res)
    let d = res.data
    return {
      ...d,
      // tempf: d.tempf == "" ? d.tempf : ((d.tempf - 32)/1.8).toFixed(1),
      // tempinf: d.tempinf == "" ? d.tempinf :((d.tempinf - 32)/1.8).toFixed(1),
      // baromabsin:(d.baromabsin*33.863882).toFixed(1),
      // baromrelin:(d.baromrelin*33.863882).toFixed(1),
      // maxdailygust:(d.maxdailygust*0.447).toFixed(1),
      // windgustmph:(d.windgustmph*0.447).toFixed(1),
      // windspeedmph:(d.windspeedmph*0.447).toFixed(1),
      // windspdmph_avg2m:(d.windspdmph_avg2m*0.447).toFixed(1),
      // windspdmph_avg10m:(d.windspdmph_avg10m*0.447).toFixed(1),
      // minutesrainin:(d.minutesrainin*25.4).toFixed(2),
      // hourlyrainin:(d.hourlyrainin*25.4).toFixed(2),
      // dailyrainin:(d.dailyrainin*25.4).toFixed(2),
      // weeklyrainin:(d.weeklyrainin*25.4).toFixed(2),
      // monthlyrainin:(d.monthlyrainin*25.4).toFixed(2),
      // yearlyrainin:(d.yearlyrainin*25.4).toFixed(2),
      // totalrainin:(d.totalrainin*25.4).toFixed(2),
      winddir10m : d.winddir_avg10m,
      winddir_avg10m: d.winddir_avg10m == "" ? "" : windDegreeToDir(d.winddir_avg10m)
    }
  }
}
export default {
  // 获取气象站列表
  getConnectData({value,time,startTime,endTime,device_name,device,webId}) {
    return $.post('/connect/get',{value,time,startTime,endTime,device_name,device,webId})
  },
  // 获取气象站列表
  getDeviceList({device_name}) {
    return $.get('/connect/list',{params: device_name})
  },
  getDeviceList1(params) {
    console.log((params.userId && params.userName && params.email))
    return $.get('/connect/list?userId=' + params.userId + '&userName=' + params.userName + '&email=' + params.email)
  },
  // 分页获取气象站列表
  getConnectPage({device_name,currentUserName,page = 1, limit = 15,email,userId}) {
    return $.post('/connect/page', {device_name,currentUserName,page,limit,email,userId})
  },
  // 获取单一气象站实时数据
  getDeviceData({ device, time,startTime,endTime}) {
    return $.post('/device_data/find', { device, time,startTime,endTime}).then(dataHelper1)
  },
  // 获取单一气象站历史数据
  getDeviceHourData({ device, time, startTime, endTime,period}) {
    return $.post('/device_data/hourData', { device, time, startTime, endTime,period}).then(dataHelper)
  },
  // 新增气象站
  setConnectData(data) {
    return $.post('/connect/add', data)
  },
  // 修改气象站
  upConnectData(data) {
    return $.post('/connect/upd', data)
  },
  // 删除气象站
  delConnectData({id}) {
    return $.post('/connect/del',{id})
  },

  // 导出气象站数据
  expoetExcl(data) {
      return service.post('/device/stat/export/data',data)
  },
  // 气象数据统计
  weatherDetail(data) {
      return service.post('/device/stat/stat/data',data)
  },
  getMaxAndMinTem(params) {
    return service.get('/statDay/list?startTime=' + params.startTime + '&endTime=' + params.endTime + '&device=' + params.device)
  }
}

// 获取最高最低温