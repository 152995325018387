import service from './service'

export default {
    // 套餐列表
    comboList(params) {
        return service.get('/member/menu/page?',{params})
    },
    // 提交订单
    order(data) {
        return service.post('/wx/pay/member',data)
    },
    // 获取订单状态
    orderStatus(params) {
        return service.get('/order/pay/status',{params})
    }
}