import $ from './base'

export default {
  getLessonList({type,name,group,page = 1, limit = 10}) {
    return $.post('/kecheng/list', {type,name,group,page,limit})
  },
  getLesson(id) {
    return $.post('kecheng/findById', {id})
  },
  getMenuList() {
    return $.post('kecheng/menuList')
  }
}